import React from "react";
import PropTypes from "prop-types";
// import theme from "../../theme/theme.yaml";

import Headline from "../Article/Headline";
import Bodytext from "../Article/Bodytext";

const Page = (props) => {
  const {
    page: {
      body,
      frontmatter: { title },
    },
  } = props;

  return (
    <React.Fragment>
      <header>
        <Headline title={title} />
      </header>
      <Bodytext body={body} />
    </React.Fragment>
  );
};

Page.propTypes = {
  page: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
};

export default Page;
