import React from "react";
import loadable from "@loadable/component";
import styled from "styled-components";
import { useMediaQuery } from "beautiful-react-hooks";
// import ReactPlayer from "react-player";

const ReactPlayer = loadable(() => import("react-player"));

const Youtube = ({ url, square }) => {
  const isLarge = useMediaQuery("(min-width: 48rem)");

  const width = "100%";
  const height = "100%";

  // if (square && isLarge) {
  //   width = "50%";
  //   height = "50%";
  // }

  if (square) {
    return (
      <ResponsiveSquare>
        <ReactPlayer
          className="react-player"
          url={url}
          light={true}
          width={width}
          height={height}
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
          }}
        />
      </ResponsiveSquare>
    );
  } else {
    return (
      <Responsive>
        <ReactPlayer
          className="react-player"
          url={url}
          light={true}
          width={width}
          height={height}
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
          }}
        />
      </Responsive>
    );
  }
};

export default Youtube;

const Responsive = styled.div`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ResponsiveSquare = styled.div`
  position: relative;
  padding-top: 100%; /* Player ratio: 100 / (1280 / 720) */

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
