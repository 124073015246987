import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../theme/theme.yaml";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Youtube from "../Youtube";

const Bodytext = (props) => {
  const { body } = props;
  const shortcodes = { Youtube };

  return (
    <StyledFragment>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
      {/* <div className="bodytext" dangerouslySetInnerHTML={{ __html: html }} /> */}
    </StyledFragment>
  );
};

Bodytext.propTypes = {
  body: PropTypes.string.isRequired,
  // theme: PropTypes.object.isRequired,
};

export default Bodytext;

const StyledFragment = styled.div`
  .bodytext {
    animation-name: bodytextEntry;
    animation-duration: ${theme.time.duration.long};

    :global(h2),
    :global(h3) {
      margin: 1.5em 0 1em;
    }

    :global(h2) {
      line-height: ${theme.font.lineHeight.s};
      font-size: ${theme.font.size.l};
    }

    :global(h3) {
      font-size: ${theme.font.size.m};
      line-height: ${theme.font.lineHeight.m};
    }

    :global(p) {
      font-size: ${theme.font.size.s};
      line-height: ${theme.font.lineHeight.xxl};
      margin: 0 0 1.5em;
    }
    :global(ul) {
      list-style: circle;
      margin: 0 0 1.5em;
      padding: 0 0 0 1.5em;
    }
    :global(li) {
      margin: 0.7em 0;
      line-height: 1.5;
    }
    :global(a) {
      font-weight: ${theme.font.weight.bold};
      color: ${theme.color.brand.primary};
      text-decoration: underline;
    }
    :global(a.gatsby-resp-image-link) {
      border: 0;
      display: block;
      margin: 2.5em 0;
      border-radius: ${theme.size.radius.default};
      overflow: hidden;
      border: 1px solid ${theme.line.color};
    }
    :global(code.language-text) {
      background: ${theme.color.neutral.gray.c};
      text-shadow: none;
      color: inherit;
      padding: 0.1em 0.3em 0.2em;
      border-radius: 0.1em;
    }
  }

  @keyframes bodytextEntry {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
